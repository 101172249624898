export const ALL_TAB_ID = 'All'

export enum NotificationTypes {
  Extraction = 'Extraction',
  Passport = 'Passport',
  Request = 'Request',
}

export interface SaiftyNotification {
  userId: string
  messageId: number
  data: any
  status: any
  messageType: string
  timestamp: number
}

export const NOTIFICATION_PREFIXES = {
  New: 'NEW_',
  Update: 'UPDATE_',
  Remove: 'REMOVE_',
}
