import { AccountInfo, Configuration, RedirectRequest, PublicClientApplication } from '@azure/msal-browser'

import { reactive, readonly } from 'vue'
import { AuthPlugin, RequiredAuthOptions } from '../types'
import useProviderData, { saiftyProvider } from './useProviderData'
import axios from 'axios'
import { UserSource } from '@coac-gmbh/saifty-main-apis'
import { closeSocketConnection } from '@/composables/useRSockets'

const SAIFTY_DEFAULT_SCOPE_ITEM = '.default'
const FALLBACK_MSAL_SCOPES = import.meta.env.VITE_MSAL_SCOPES?.split(',').map((s) => s.trim()) || [
  'email',
  'User.Read',
  'profile',
]
const MSAL_SCOPES = !window.ENV.MSAL_SCOPES.includes('**MSAL_SCOPES**')
  ? window.ENV.MSAL_SCOPES.split(',').map((s) => s.trim()) || FALLBACK_MSAL_SCOPES
  : FALLBACK_MSAL_SCOPES
const SAIFTY_SCOPES = [SAIFTY_DEFAULT_SCOPE_ITEM]

const scopesFromENV =
  import.meta.env.VITE_MSAL_CUSTOM_SCOPES?.split(',').map((s) => s.trim()) ||
  window.ENV.MSAL_CUSTOM_SCOPES.split(',').map((s) => s.trim())
if (scopesFromENV && !scopesFromENV.includes('**MSAL_CUSTOM_SCOPES**')) {
  scopesFromENV.forEach((scope) => {
    // Avoid add an empty scope
    if (scope) {
      SAIFTY_SCOPES.push(scope)
    }
  })
}

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.readAsDataURL(blob)
  })
}

export function setupAuthPluginMSAL(options: RequiredAuthOptions, msalConfig: Configuration): AuthPlugin {
  const router = options.router
  const { isAuthenticated, user, error, loaded, waitUntilLoaded, syncUserWithBackend, getUserPermissions } =
    useProviderData()
  const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig)
  initialize()

  async function initialize() {
    error.value = undefined
    try {
      /* 
        MSAL works with a login with redirection, first saifty redirects to the Microsoft universal login, if something 
        fails, Microsoft redirects to the application with a message in the hash, so, in the second initialize try, we 
        need to validates if we have some error in the hash before redirects again to the Microsoft universal login in
        order to avoid an infinite loop of redirections.

        The navigation guards does not detect the 'error' in the path because it waits until the 'loaded' value is set in true,
        but the redirection happens before the loaded values was set in true.
      */
      if (window.location.hash.includes('error=')) throw new Error(window.location.hash)
      const redirectResult = await msalInstance.handleRedirectPromise()
      if (redirectResult) {
        await loadUserState()

        if (redirectResult.state) {
          try {
            const { redirectTo } = JSON.parse(redirectResult.state)
            console.log('Redirecting to:', redirectTo)
            router.push(redirectTo)
          } finally {
            // Not possible to get the redirectTo
          }
        }
      }
      // TODO: Redirect to Wizard when first login of the user
    } catch (exception) {
      console.error('MSAL authentication error:', exception)
      // We add the error so that the navigation guard can redirect to the error page and prevents infinite redirections
      error.value = String(exception)
    } finally {
      // Everything is loaded, it might or not have loaded an user
      loaded.value = true
    }
  }

  async function loadUserState() {
    const accounts: AccountInfo[] = msalInstance.getAllAccounts()
    if (accounts && accounts.length > 0) {
      const msalUser = msalInstance.getAllAccounts()[0] as AccountInfo
      msalInstance.setActiveAccount(msalUser)
      const picture = await getPictureURL()
      const idToken = await getIdToken()

      user.value = {
        id: msalUser.idTokenClaims?.sub || msalUser.homeAccountId,
        email: (msalUser.idTokenClaims?.email as string) || msalUser.username,
        nickname: msalUser.name || msalUser.username,
        picture: picture,
        just_registered: false,
        isAdmin: (import.meta.env.VITE_ADMIN_EMAILS || window.ENV.ADMIN_EMAILS)
          ?.split(',')
          .map((s) => s.trim())
          .includes(msalUser.username),
        permissions: [],
      }

      isAuthenticated.value = true
      if (isAuthenticated.value && user.value.email && idToken) {
        saiftyProvider.value = 'msal'
        await syncUserWithBackend(user.value.email, UserSource.Maad, idToken)
        // The permissions are loaded after call the 'syncUserWithBackend'
        user.value = { ...user.value, permissions: getUserPermissions(msalUser.idTokenClaims) }
      }
    }
  }

  function changePassword() {
    window.open(
      'https://account.live.com/password/change?refd=account.microsoft.com&fref=home.banner.changepwd',
      '_blank'
    )
    return Promise.resolve()
  }

  function loginWithRedirect(redirectTo?: string) {
    const loginRequest: RedirectRequest = {
      scopes: MSAL_SCOPES,
    }
    if (redirectTo) {
      loginRequest.state = JSON.stringify({ redirectTo })
    }
    msalInstance.loginRedirect(loginRequest)
  }

  async function signup() {
    loginWithRedirect()
  }

  async function logout() {
    await msalInstance.logoutRedirect()
    closeSocketConnection()
    isAuthenticated.value = false
    user.value = undefined
    saiftyProvider.value = undefined
  }

  async function getAccessToken(): Promise<string> {
    const activeAccount = msalInstance.getActiveAccount()
    if (!activeAccount) {
      throw 'No active account found'
    }
    const request = {
      account: activeAccount,
      scopes: SAIFTY_SCOPES.map((r) => {
        if (r === SAIFTY_DEFAULT_SCOPE_ITEM) {
          return msalConfig.auth.clientId + '/' + r // We only need to change the prefix in the default scope
        } else {
          return r
        }
      }),
    }
    const token = await msalInstance.acquireTokenSilent(request)
    return token.accessToken
  }

  async function getIdToken(): Promise<string> {
    const activeAccount = msalInstance.getActiveAccount()
    if (!activeAccount) {
      throw 'No active account found'
    }
    const request = {
      account: activeAccount,
      scopes: SAIFTY_SCOPES.map((r) => {
        if (r === SAIFTY_DEFAULT_SCOPE_ITEM) {
          return msalConfig.auth.clientId + '/' + r // We only need to change the prefix in the default scope
        } else {
          return r
        }
      }),
    }
    const token = await msalInstance.acquireTokenSilent(request)
    return token.idToken
  }

  async function getGraphAccessToken(): Promise<string> {
    const activeAccount = msalInstance.getActiveAccount()
    if (!activeAccount) {
      throw 'No active account found'
    }
    const request = {
      account: activeAccount,
      scopes: MSAL_SCOPES,
    }
    const token = await msalInstance.acquireTokenSilent(request)
    return token.accessToken
  }

  async function getPictureURL(): Promise<string> {
    try {
      const token = await getGraphAccessToken()
      const headers = {
        Authorization: `Bearer ${token}`,
      }
      const { data: pictureArrayBuffer } = await axios.get<Blob>('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers,
        responseType: 'blob',
      })
      return await blobToBase64(pictureArrayBuffer)
    } catch (error) {
      return options.defaultPictureURL
    }
  }

  /*
   * "reactive" unwraps 'ref's, therefore using the .value is not required.
   * E.g: from "auth.isAuthenticated.value" to "auth.isAuthenticated"
   * but when using destructuring like: { isAuthenticated } = useAuth() the reactivity over isAuthenticated would be lost
   * this is not recommended but in such case use toRefs: { isAuthenticated } = toRefs(useAuth())
   * See: https://v3.vuejs.org/guide/reactivity-fundamentals.html#ref-unwrapping
   * And: https://v3.vuejs.org/guide/reactivity-fundamentals.html#destructuring-reactive-state
   */
  const unWrappedRefs = reactive({
    isAuthenticated,
    user,
    loaded,
    error,
    loginWithRedirect,
    signup,
    logout,
    getAccessToken,
    waitUntilLoaded,
    changePassword,
  })

  return readonly(unWrappedRefs)
}
