import { DataObjectData } from '@coac-gmbh/saifty-main-apis'

export interface PassportSection {
  name: string
  items: { name: string }[]
  sections: PassportSection[]
}

export const PASSPORTS_REQUESTS_INGREDIENTS_FROM_SUPPLIER_KEY = 'INGREDIENTS_REQUESTED'

export const INGREDIENT_PREFIX_KEY = 'INGREDIENT'

export const SDS_PASSPORT_ID = 'SDS_TEMPLATE' // NOTE: We can't to update the 'template' word to 'passport' because this ID was saved in many passports, so, it may cause many errors
export const TDS_PASSPORT_ID = 'TDS_TEMPLATE'

export const DEFAULT_PASSPORT_IDS_LIST = [SDS_PASSPORT_ID, TDS_PASSPORT_ID]

export const CUSTOM_PASSPORT_SUBSECTION_SPECIAL_CHARACTER = '_'

export interface IngredientData {
  id: string
  name: string
  items: DataObjectData[]
}

export interface PassportRequestIngredient {
  id: number
  NAME: string
  CAS?: string
  REACH_NO?: string
  FORMULAR?: string
  MINIMAL_COMPOSITION?: string
  MAXIMAL_COMPOSITION?: string
  AVERAGE_COMPOSITION?: string
}
