export const NEW_OBJECT_URL_VALUE = 'new'

export type Writable<T> = {
  -readonly [P in keyof T]: T[P]
}

export enum OptimisticActions {
  Delete,
  Create,
  Update,
}

export interface SimpleFormOption {
  name: string
  value: string
}

export type SimpleFormOptions = Array<SimpleFormOption>

export type BSVariant = 'primary' | 'secondary' | 'danger' | 'warning' | 'success' | 'info'

export enum BSVariantEnum {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
  Warning = 'warning',
  Success = 'success',
  Info = 'info',
}

export type Tab = { label: string; id: string }

export type BasicItem = { name: string; value: string }

export interface XLSXFields {
  [key: string]: string
}

export type LoadingSize = 'sm' | 'lg'
