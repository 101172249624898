import i18n from '@/plugins/i18n'

export enum SorTypes {
  NameDescending = 'a-to-z',
  NameAscending = 'z-to-a',
  DateDescending = 'newer',
  DateAscending = 'oldest',
}

export type SortType =
  | ''
  | SorTypes.NameDescending
  | SorTypes.NameAscending
  | SorTypes.DateDescending
  | SorTypes.DateAscending

export const sortOptions = [
  // eslint-disable-next-line
  // @ts-ignore
  { name: i18n.global.t('None'), value: '' },
  { name: i18n.global.t('A to Z'), value: SorTypes.NameDescending },
  { name: i18n.global.t('Z to A'), value: SorTypes.NameAscending },
  { name: i18n.global.t('Newer'), value: SorTypes.DateDescending },
  { name: i18n.global.t('Oldest'), value: SorTypes.DateAscending },
]
