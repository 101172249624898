import { ref } from 'vue'
import Modal from '@/components/modals/Modal.vue'

const rateLimitingModal = ref<InstanceType<typeof Modal>>()

export default function useModal() {
  function openRateLimitingModal() {
    rateLimitingModal.value?.show()
  }

  function hideRateLimitingModal() {
    rateLimitingModal.value?.hide()
  }

  return {
    rateLimitingModal,
    openRateLimitingModal,
    hideRateLimitingModal,
  }
}
