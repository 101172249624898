import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { ALL_TAB_ID, NotificationTypes, SaiftyNotification, Tab } from '@/config'

const notifications = ref([] as SaiftyNotification[])

const notificationsNotRead = computed(() => {
  return notifications.value.filter((notification) => !notification.status)
})

export default function useNotifications() {
  const { t } = useI18n()
  const currentNotificationTabSelected = ref<string>(ALL_TAB_ID)
  const notificationsTabsSections = <Tab[]>[
    { label: t('All'), id: ALL_TAB_ID },
    // { label: t('Extractions'), id: NotificationTypes.Extraction.toString() },
    // { label: t('Passports'), id: NotificationTypes.Passport.toString() },
    // { label: t('Requests'), id: NotificationTypes.Request.toString() },
  ]

  const notificationsFiltered = computed(() => {
    if (currentNotificationTabSelected.value) {
      if (currentNotificationTabSelected.value === ALL_TAB_ID) {
        return notifications.value
      } else {
        return notifications.value.filter(
          (notification) =>
            getNotificationTypeFromNotification(notification) &&
            getNotificationTypeFromNotification(notification) === currentNotificationTabSelected.value
        )
      }
    } else {
      return notifications.value
    }
  })

  const notificationsNotReadFiltered = computed(() => {
    if (currentNotificationTabSelected.value) {
      if (currentNotificationTabSelected.value === ALL_TAB_ID) {
        return notificationsNotRead.value
      } else {
        return notificationsNotRead.value.filter(
          (notification) =>
            getNotificationTypeFromNotification(notification) &&
            getNotificationTypeFromNotification(notification) === currentNotificationTabSelected.value
        )
      }
    } else {
      return notificationsNotRead.value
    }
  })

  function onTabClicked(tab: string) {
    currentNotificationTabSelected.value = tab
  }

  // TODO: Improve the logic
  function getNotificationTypeFromNotification(notification: SaiftyNotification) {
    if (notification.messageType.includes('EXTRACTION')) return NotificationTypes.Extraction
    else if (notification.messageType.includes('PASSPORT')) return NotificationTypes.Passport
    else if (notification.messageType.includes('REQUEST')) return NotificationTypes.Request
    else ALL_TAB_ID
  }

  return {
    notifications,
    notificationsNotRead,
    notificationsFiltered,
    notificationsNotReadFiltered,
    notificationsTabsSections,
    currentNotificationTabSelected,
    onTabClicked,
  }
}
