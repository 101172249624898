import i18n from '@/plugins/i18n'

export interface HelperTip {
  title: string
  text: string
}

export const GLOBAL_HELP_BUTTON_LOCATION = '#teleportAdditionalNavbarButtonsTarget'

export const DASHBOARD_TIPS = [
  {
    // eslint-disable-next-line
    // @ts-ignore
    title: i18n.global.t('Get started using SAIFTY with a little learning: 5 steps to make it easy'),
    text: i18n.global.t('Go from extracting data to downloading an excel file.'), // The missing word 'Just click to start' is located on dashboard page because this text has a special feature
  },
  {
    title: i18n.global.t('Hello and welcome! I hope you are doing fine'),
    text: i18n.global.t(
      'This dashboard gives you the overview on the open approvals that need your attention inside the activities, your available data that has been extracted and of course your configured extraction pipelines - the extractions. Click on the arrows to read more about the three sections!'
    ),
  },
  {
    title: i18n.global.t('My Activities'),
    text: i18n.global.t(
      'I have extracted all relevant information from your documents, but as you know: Teamwork makes the dream work. So please have a look into the documents and verify if everything is correct. I am learning every day! When you approve my extraction, the data is added into your data assets and then also available for export and search requests.'
    ),
  },
  {
    title: i18n.global.t('My Data'),
    text: i18n.global.t(
      'All information that I extracted and you approved is loaded into data assets. You can define several data assets, to structure your data in any way you like. You can for example define an own data asset for each extraction or perhaps based on the documents you want to process? Each data asset can be used for exports and is also used to match information based on your input on the search page.'
    ),
  },
  {
    title: i18n.global.t('My Extractions'),
    text: i18n.global.t(
      'With the extractions, you can tell me how information should be extracted from documents. You can configure several processes with different parameters. For each extraction, you can define what you would like to let me extract, what quality you are expecting and also where to store the information. '
    ),
  },
]

export const FLOWS_TIPS = [
  {
    title: i18n.global.t('Tired of copy-and-paste? Create a new extraction!'),
    text: i18n.global.t(
      'You can find all configured extractions inside the list below, but perhaps you want to setup a new extraction with some different configuration? Then just click on "New Extraction" in the upper right corner. Of course you can also change the configuration of each extraction anytime by clicking on the gear at the right end. Open approvals are noted inside the box.'
    ),
  },
]

export const APPROVALS_TIPS = [
  {
    title: i18n.global.t('Wow, that was a whole lot of work!'),
    text: i18n.global.t(
      'I have made the extractions and prepared the information as good as possible. Each entry in the list below represents an own document. You can open the approval tool by just clicking into it. Based on your configuration in the extraction, I have marked everything in green that should be fine. The items in yellow should be checked by you. Sometimes a real expert is required or the documents are just bad and I am just a hardworking fellow.'
    ),
  },
]

export const DATA_TIPS = [
  {
    title: i18n.global.t('Here we go! Your data...'),
    text: i18n.global.t(
      'Everything I extracted from the documents using the extractions and that you approved is now available inside the data assets listed below. You can open a data asset and see the different extraction results or just download all information as an Excel. For more functionality, please contact the SAIFTY service team - there is more available that is not released here...'
    ),
  },
]

export const LIBRARY_TIPS = [
  {
    title: i18n.global.t('Sometime it is hard to keep the overview'),
    text: i18n.global.t(
      'But here you can find every document that has been processed inside SAIFTY. The library should give you access to the original document with some key information extracted from the document.'
    ),
  },
]

export const FLOWS_DETAIL_TIPS = [
  {
    title: i18n.global.t('SDS Documents upload'),
    text: i18n.global.t(
      'The easiest way to upload documents into the extraction is the manual upload using drag-and-drop or the selection from the file system. Optionally you can also configure a mail postbox that is observed by SAIFTY all the time for new mails and documents.'
    ),
  },
  {
    title: i18n.global.t('Extract Data from SDS'),
    text: i18n.global.t(
      'What sections of the SDS are relevant for you? By default, SAIFTY extractions all sections for you. But perhaps you are not interested in some sections. Then unselect this chapter to also reduce the complexity and effort during the approval.'
    ),
  },
  {
    title: i18n.global.t('Validate SDS'),
    text: i18n.global.t(
      'Please define how you want to validate information from the SDS. The confidence value defines what can be automatically approve by SAIFTY to reduce your effort. We are offering several process types for the data extraction - we would recommend to leave the default approach. Last but not least, SAIFTY Cloud can match the extracted data to the SAP standard catalogue and also to the EuPhraC catalogue.'
    ),
  },
  {
    title: i18n.global.t('Store Data'),
    text: i18n.global.t(
      'Please select an existing data asset that has been created in the past or create a new data asset. A data asset is something like a folder that contains all information after you approved the information extracted by SAIFTY.'
    ),
  },
]

export const APPROVALS_DETAIL_TIPS = [
  {
    title: '',
    text: i18n.global.t(
      'The approval page provides you an overview on all extracted information. You can see all extractions in green, that are approved. In yellow, you can find everything that needs to be verified. Some information is approved from the beginning, based on your confidence-configuration for the extraction. Please also activate the advanced mode to see the document and extractions in parallel. You can use the Approve button when you are done to add the information into the data asset and to make the information available for the search functionality.'
    ),
  },
]
