import { RouteLocationRaw, Router } from 'vue-router'
import { useAuth } from '@/auth'
import useRolesAndPermissions from './composables/useRolesAndPermissions'
import { restrictedRoutesByCustomer } from './config/customers'

export function configureNavigationGuards(router: Router) {
  router.beforeEach(async (to): Promise<boolean | RouteLocationRaw> => {
    const auth = useAuth()
    const { permissionDeniedRoute, userRolesWithPermissions } = useRolesAndPermissions()
    await auth.waitUntilLoaded()

    // Note: To add the 'isMaintainer' value to some user, set this to the specific user in the auth0 page
    if (
      !to.meta.public &&
      (import.meta.env.VITE_MAINTENANCE_MODE === 'true' || window.ENV.MAINTENANCE_MODE) &&
      !auth.user?.app_metadata?.isMaintainer
    ) {
      return {
        name: 'maintenance',
      }
    }

    if (
      typeof to.name === 'string' &&
      (restrictedRoutesByCustomer().includes(to.name) ||
        userRolesWithPermissions.value.restrictedPages.includes(to.name))
    ) {
      if (to.name === 'dashboard') {
        // If user doesn't have permissions to open the dashboard, we redirect him to the search page as fallback
        return {
          name: 'home',
        }
      } else {
        return permissionDeniedRoute
      }
    }

    return true
  })
}
