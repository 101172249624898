import { ref } from 'vue'
import { useAuth } from '@/auth'

const tenantIDForWhichToActOnBehalf = ref<string>()

export default function useAdminTools() {
  function canActOnBehalfOfOtherTenants() {
    const auth = useAuth()
    return auth.user?.app_metadata?.canActOnBehalfOfOtherTenantIds === true || auth.user?.isAdmin
  }

  return {
    tenantIDForWhichToActOnBehalf,
    canActOnBehalfOfOtherTenants,
  }
}
