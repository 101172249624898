import { useMediaQuery } from '@vueuse/core'

export function useDevice() {
  const isSmallScreen = useMediaQuery('(max-width: 349px)')
  const isMobile = useMediaQuery('(max-width: 767px)')
  const isDesktop = useMediaQuery('(min-width: 768px)')
  const isMobileApp: boolean = import.meta.env.VITE_MOBILE_APP === 'true'

  return { isMobile, isSmallScreen, isDesktop, isMobileApp }
}
